<template>
  <div class="round-card" :class="data.class">
    <slot />
    <h3 class="title" v-html="data.title" />
    <div class="description" v-html="data.description"></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="less" scoped>
.round-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 320px;
  border-radius: 12px;
  margin-right: 24px;
  background: var(--ds-color-primary-25);
  border: 1px solid var(--ds-color-primary-50);

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
  }

  .title {
    margin-top: 55px;

    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  .description {
    margin-top: 25px;
    color: #7E8085;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 0 18px;

    :deep(.bold) {
      font-weight: var(--ds-weight-semi-bold);
    }
  }
}
</style>
