<template>
  <div class="tabs">
    <div class="tabs-header">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        :ref="tab.id"
        class="tab"
        :class="{ selected: tab.id === selectedId }"
        @click="handleSelect(tab.id)"
      >
        <div class="title">{{ tab.title }}</div>
      </div>
      <div
        ref="ink-bar"
        class="ink-bar"
        :style="inkBarStyle"
      />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array,
      default: [],
    },
  },
  emits: ['click', 'update:modelValue'],
  data() {
    return {
      inkBarStyle: {
        left: 0,
        width: 0,
      },
    }
  },
  computed: {
    selectedId() {
      return this.modelValue
    },
  },
  watch: {
    modelValue(tabId) {
      this.updateInkBarStyle(tabId)
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.$nextTick(() => this.handleWindowResize())
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  methods: {
    handleSelect(i) {
      this.$emit('update:modelValue', i)
    },
    updateInkBarStyle(tabId) {
      if (!tabId) return

      const tabNode = this.$refs[tabId]?.[0]
      this.inkBarStyle = {
        left: `${tabNode ? tabNode.offsetLeft : 0}px`,
        width: `${tabNode ? tabNode.offsetWidth : 0}px`,
      }
    },
    handleWindowResize() {
      this.updateInkBarStyle(this.selectedId)
    },
  },
}
</script>

<style lang="less" scoped>
.tabs {
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
}

.tabs-header {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 3px solid #f4f7fb;

  font-size: 24px;
  font-weight: var(--ds-weight-semi-bold);
  min-width: 50%;

  @media @bp-md {
    white-space: nowrap;
  }

  @media @bp-md-down {
    font-size: var(--ds-font-md);
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 24px 0;
    width: 50%;
    color: var(--ds-color-gray-50);
    transition: all 0.2s ease;

    @media @bp-mobile {
      padding: 12px 0;
    }

    @media @bp-md-down {
      flex-direction: column;
      justify-content: flex-start;

      .title {
        margin-left: 0;
        margin-top: 14px;
      }
    }

    .title {
      margin-left: 14px;
      text-align: center;
    }

    &.selected,
    &:hover {
      cursor: pointer;
      color: var(--ds-color-primary-100);
    }
  }

  .ink-bar {
    position: absolute;
    width: 0;
    height: 5px;
    bottom: -3px;
    background: var(--ds-color-primary-100);
    border-radius: 30px 30px 0 0;
    transition:
      left 0.3s,
      width 0.3s;
  }
}
</style>
