<template>
  <Section class="features flex-col flex-center">
    <Tabs class="flex-col h-center v-left" @click="handleTabClick" v-model="selectedTabId" :tabs="tabs">
      <Tab :ref="tabs[0].id" class="flex-row h-left v-center" v-show="selectedTabId === tabs[0].id">
        <div class="space" />
        <RoundCard :data="invest[0]">
          <img
            class="image simu"
            src="~/assets/imgs/feature-simu.webp"
            alt="Un simulateur de rendement"
            width="58"
            height="58"
            loading="lazy"
          />
        </RoundCard>
        <RoundCard :data="invest[1]">
          <img
            class="image target"
            src="~/assets/imgs/feature-target.webp"
            alt="Une rentabilité calculée pour toutes les annonces"
            width="74"
            height="70"
            loading="lazy"
          />
        </RoundCard>
        <RoundCard :data="invest[2]">
          <img
            class="image settings"
            src="~/assets/imgs/feature-settings.webp"
            alt="Une recherche de biens automatisée"
            width="64"
            height="59"
            loading="lazy"
          />
        </RoundCard>
        <RoundCard class="last" :data="invest[3]">
          <img
            class="image expert"
            src="~/assets/imgs/feature-expert.webp"
            alt="Des experts à votre service"
            width="74"
            height="74"
            loading="lazy"
          />
        </RoundCard>
        <div class="space" />
      </Tab>
      <Tab
        v-show="selectedTabId === tabs[1].id"
        :ref="tabs[1].id"
        class="flex-row h-left v-center"
        style="-webkit-user-select: none;"
        tabindex="-1"
      >
        <div class="flex-row h-left v-center">
          <div class="space" />
          <RoundCard
            v-for="(feature, index) in specialFeatures"
            :key="index"
            :data="feature.data"
            :class="{ last: index + 1 === specialFeatures.length}"
          >
            <img
              class="image"
              :class="feature.img.class"
              :src="feature.img.src"
              :alt="feature.img.alt"
              :width="feature.img.width"
              :height="feature.img.height"
              loading="lazy"
            />
          </RoundCard>
          <div class="space" />
        </div>
      </Tab>
    </Tabs>
    <NuxtLink :to="currentTab.link">
      <uikit-button
        class="button"
        type="primary"
        @click="handleButtonClick(currentTab.link)"
      >
        Commencer gratuitement
        <template #right-icon>
          <uikit-arrow-icon gradient="no-gradient" class="arrow-icon"/>
        </template>
      </uikit-button>
    </NuxtLink>
  </Section>
</template>

<script>
import { UikitButton, UikitArrowIcon } from '@hz/ui-kit'

export default {
  components: {
    UikitButton,
    UikitArrowIcon
  },
  props: {
    context: {
      type: String,
      default: 'home',
    },
    specialFeatures: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['pageChange'],
  computed: {
    isHome() {
      return this.context === 'home'
    },
    tabs() {
      return [
        {
          id: 'fin-tab',
          title: 'Calculer la rentabilité locative',
          link: { name: 'rendement-locatif', query: { tabId: 'simulate-efficiency' } },
        },
        {
          id: 'pin-tab',
          title: this.isHome ? 'Piloter sa gestion locative' : 'Trouver un bien rentable',
          link: { name: this.isHome ? 'gestion-locative' : 'rendement-locatif', query: this.isHome ? { } : { tabId: 'find-property' } },
        },
      ]
    },
    currentTab() {
      return this.tabs.find(tab => tab.id === this.selectedTabId)
    },
  },
  data() {
    return {
      selectedTabId: 'fin-tab',
      invest: [
        {
          title: 'Un simulateur<br />de rendement',
          description:
            "Détectez rapidement <span class='bold'>la rentabilité de n'importe quel bien.</span> Impôts, fiscalité, charges, etc. sont intégrés pour un résultat précis.",
          class: 'bg-purple-light',
        },
        {
          title: 'Une rentabilité calculée<br />pour toutes les annonces',
          description:
            "<span class='bold'>Téléchargez notre extension</span> pour calculer en direct la rentabilité de toutes les annonces immobilières que vous consultez.",
          class: 'bg-cyan-light',
        },
        {
          title: 'Une recherche de<br />biens automatisée',
          description:
            "<span class='bold'>Ne parcourez plus tous les sites de vente immobilière</span> pour trouver un bien, on le fait pour vous. Vous êtes alerté dès qu'une annonce correspond à votre recherche.",
          class: 'bg-pink-light',
        },
        {
          title: 'Des experts à<br />votre service',
          description:
            "Avocats, comptables, fiscalistes, etc., <span class='bold'>contactez nos experts</span> et obtenez des réponses claires à vos questions.",
          class: 'bg-orange-light',
        },
      ],
    }
  },
  methods: {
    handleTabClick(tabTitle) {
      if (this.isHome) {
        useTracking().track('Click Onglet Choix Profil', {
          Choix: tabTitle === 'Piloter sa gestion locative' ? 'gestionLocative' : 'investLocatif',
        })
      }
    },
    handleButtonClick(tab) {
      if (!this.isHome) {
        this.$emit('pageChange', { key: `${tab.query.tabId}-${Math.random()}` })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.features :deep(.section-container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tab {
  padding: 58px var(--ds-gutter-sm) var(--ds-gutter-sm) var(--ds-gutter-sm);
  overflow-y: hidden;
  width: 100%;
  max-width: 1247px;
}

.button {
  margin-top: 28px;
}

:deep(.round-card) {
  width: 282px;
  flex-shrink: 0;
}

:deep(.round-card.last) {
  margin-right: 15px !important;
}

:deep(.round-card) {
  .space-around {
    margin: 13px 0 14px 0;
  }
}

:deep(.tabs-header) {
  @media @bp-md-down {
    margin: 0 15px;
  }
}

.image {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
}

.space {
  min-width: 1px;
  min-height: 320px;
}

.arrow-icon {
  width: 20px;
  height: auto;
}
</style>
